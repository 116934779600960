import get from 'lodash/get'
import omit from 'lodash/omit'
import forEach from 'lodash/forEach'
import { reactive } from '@vue/composition-api'

export default (ctx) => {
  const {
    root: { $store },
  } = ctx

  const initialData = reactive({
    id: null,
    lang: null,
    name: null,
    url: null,
    content: null,
    is_default: false,
    created_at: null,
    updated_at: null,
    updated_by: null,
    created_by: null,
  })

  const formData = reactive({
    id: null,
    lang: null,
    name: null,
    url: null,
    content: null,
    is_default: false,
    created_at: null,
    updated_at: null,
    updated_by: null,
    created_by: null,
  })

  const prepareFormData = (result) => {
    const data = omit(result, ['__typename'])
    forEach(formData, (value, key) => {
      formData[key] = data[key]
      initialData[key] = data[key]
    })
    formData.revision_id = data.revision_id
    formData.url = formData.url ? decodeURIComponent(formData.url) : formData.url
  }

  const fetchByRouteId = () => {
    const id = get(ctx.root.$router.currentRoute.params, 'id', null)
    if (id) {
      formData.id = id
      $store
        .dispatch('footer/fetchOne', id)
        .then((result) => {
          prepareFormData(result)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return {
    initialData,
    formData,
    prepareFormData,
    fetchByRouteId,
  }
}
