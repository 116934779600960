<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>Name </span><span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Name" 
                rules="required">
                <vs-input
                  :disabled="formData.is_default"
                  v-model="formData.name" 
                  class="w-full" 
                  name="name" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>URL </span><span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="URL" 
                rules="required">
                <vs-input
                  :disabled="formData.is_default"
                  v-model="formData.url" 
                  class="w-full" 
                  name="url" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout class="flex flex-col">
            <FormLabel custom-class="vx-col w-full md:w-6/12 pt-2">
              <span>แก้ไข footer (สีเขียวของลิงค์จะแสดงที่หน้าเว็บอัตโนมัติ) </span><span class="text-danger">*</span>
              <p class="text-warning">**Support การ Upload Video ขนาดไม่เกิน 98MB</p>
            </FormLabel>
            <div class="vx-col w-full ">
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Content" 
                rules="required">
                <div class="content-editor">
                  <!-- <quill-editor 
                    v-model="formData.content" 
                    class="editor" /> -->
                  <Wyswyg                     
                    :folder="`${baseFolder}/footer/wyswyg`"
                    v-model="formData.content" />
                </div>
                <span
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel />
            <FormContent>
              <vs-button 
                @click="submit(context)"
              >
                {{ formData.id ? 'Update' : 'Submit' }}
              </vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import useFooterUtil from '@/use/useFooterUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import Wyswyg from '@/components/Wyswyg'
import env from '@/env'
import get from 'lodash/get'
import omit from 'lodash/omit'

export default {
  name: 'FooterForm',
  components: {
    ValidationObserver,
    Wyswyg
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError, ...notifyFn } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const {
      initialData,
      formData,
      fetchByRouteId,
    } = useFooterUtil(ctx)
    const baseFolder = ref(env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)
    const crudForm = useCrud(ctx, 'footer')
    crudForm.routePrefix.value = 'footer'

    onMounted(() => {
      fetchByRouteId()
    })

    const updateItem = ({ validate }) => {
      validate().then((isValid) => {
        if (!isValid) return 
        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        const updated_by = email

        // add user created by
        if (!created_by) {
          created_by = email
        }

        const decodeUrl = decodeURIComponent(formData.url)

        $store
          .dispatch('footer/updateItem', { initialData,
            formData: {
              ...omit(formData, ['updated_at', 'created_at']),
              url: decodeUrl,
              created_by,
              updated_by
            }
          })
          .then(() => {
            $router.push({ name: 'footer' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            console.log('error', JSON.stringify(error))
            notifyError({ text: JSON.stringify(error) })
          })
      })
    }

    const submit = (ctx) => {
      const existingDb = $store.state.footer.items.find(v => v.url === formData.url)
      if (existingDb && existingDb.id !== formData.id) { 
        return notifyError({
          text: 'ข้อมูลมีอยู่ในระบบ ไม่สามารถเพิ่มข้อมูลได้'
        })
      }
      const decodeUrl = decodeURIComponent(formData.url)

      if (formData.id) {
        notifyFn.confirm({
          callback: () => updateItem(ctx) 
        })
      } else {
        crudForm.addItem(ctx, {
          ...formData,
          url: decodeUrl
        })
      }
    }

    watch(
      () => formData.url,
      (newValue) => {
        if (newValue) {
          const decodeUrl = decodeURIComponent(newValue)
          if (decodeUrl !== formData.url) {
            formData.url = decodeUrl
          }
        }
      }
    )

    return {
      ...crudForm,
      baseFolder,
      updateItem,
      formData,
      submit,
    }
  },
}
</script>
